<template>
	<div class="ui inverted menu" style="border-radius: 0px; margin: 0 !important; width: 100%; top: 0; position: fixed; z-index: 1000;">
		<sui-menu-item @click="toggleSidebar()" class="mobile">
			<sui-icon name="bars" style="font-size: 20px; margin: 0" />
		</sui-menu-item>
		<sui-menu-item class="mobile" style="font-weight: bold;">
			Cebada Admin
		</sui-menu-item>
		<template v-for="i in NAVBAR_ITEMS">
			<sui-menu-item v-if="!i.items || i.items.length==0" @click="i.onClick()" class="desktop">
				{{ i.text }}
				<sui-label color="red" size="mini" v-if="i.badge && i.badge>0" style="margin-left: 5px;">{{i.badge}}</sui-label>
			</sui-menu-item>
			<sui-dropdown class="desktop" v-if="i.items && i.items.length>0" item :text="i.text">
				<sui-label color="red" size="mini" v-if="i.badge && i.badge>0" style="margin-left: 5px;">{{i.badge}}</sui-label>
				<sui-dropdown-menu>
					<sui-menu-item v-for="j in i.items" @click="j.onClick()">
						{{ j.text }}
						<sui-label color="red" size="mini" v-if="j.badge>0" style="margin-left: 5px;">{{j.badge}}</sui-label>
					</sui-menu-item>
				</sui-dropdown-menu>
			</sui-dropdown>
		</template>
		<SuiMenuItem position="right" as="div">
      		<SuiButton size="mini" color="olive" @click="createOrder()">Crear pedido</SuiButton>
    	</SuiMenuItem>
		<sui-menu-menu position="right" style=" width: fit-content !important;">
			<sui-dropdown item :text="userName">
				<sui-dropdown-menu>
					<sui-menu-item @click="comissions()">Comisiones</sui-menu-item>
					<sui-menu-item @click="sellerClients()">Mis Clientes</sui-menu-item>
					<sui-menu-item @click="debt()">Mis Deudas</sui-menu-item>
					<sui-menu-item @click="logout()">Cerrar Sesión</sui-menu-item>
				</sui-dropdown-menu>
			</sui-dropdown>
		</sui-menu-menu>
	</div>
</template>

<script>
import { ref, computed, inject  } from 'vue'
import { useRouter } from "vue-router";
import { decryptData } from '../../../Util';
import Api from '../../api/Api';


export default {
	setup () {
		
		const router = useRouter();
		const adminType = decryptData(localStorage.getItem('adminType'));
		const userName = ref(decryptData(localStorage.getItem('user')));
		const count = inject('count');
		const sidebar = inject('sidebar')
		const toggleSidebarP = inject('toggleSidebar');
		let userPermissions = decryptData(localStorage.getItem("permissions"));
		userPermissions = userPermissions ? userPermissions.split(',').map(Number) : [];

		const pushRouter = page=>{			
			sidebar.value = false;
			router.push({ name: page });
		}
				
		const logout = async ()=>{
			localStorage.clear();
			sessionStorage.clear();
			Api.logout();
			pushRouter('Login');
		}

		const users = async ()=>{
			return pushRouter('Users')
		}

		const home = async ()=>{
			return pushRouter('Home')
		}

		const products = async ()=>{
			return pushRouter('Products')
		}

		const clients = async ()=>{
			return pushRouter('Clients')
		}

		const groups = async ()=>{
			return pushRouter('Groups')
		}

		const clientProspects = async ()=>{
			return pushRouter('ClientsProspects')
		}
		
		const clientTypes = async ()=>{
			return pushRouter('ClientTypes')
		}

		const clientOrders = async ()=>{
			return pushRouter('ClientsOrders')
		}
		const createOrder = async ()=>{
			return pushRouter('CreateOrder')
		}
		const clientsSalesMap = async ()=>{
			return pushRouter('ClientsSalesMap')
		}

		const routes = async ()=>{
			return pushRouter('Routes')
		}

		const ranges = async ()=>{
			return pushRouter('Ranges')
		}

		const productTypes = async ()=>{
			return pushRouter('ProductTypes')
		}

		const productPresentations = async ()=>{
			return pushRouter('ProductUnits')
		}

		const suppliers = async ()=>{
			return pushRouter('Suppliers')
		}

		const warehouses = async ()=>{
			return pushRouter('Warehouses')
		}

		const runs = async ()=>{
			return pushRouter('Runs')
		}

		const orders = async ()=>{
			return pushRouter('Orders')
		}

		const sellerClients = async ()=>{
			return pushRouter('SellerClients')
		}

		const sellerReport = async ()=>{
			return pushRouter('SellerReport')
		}

		const promotions = async ()=>{
			return pushRouter('Promotions')
		}

		const promotionsCreate = async ()=>{
			return pushRouter('CreatePromotions')
		}

		const promotionsList = async ()=>{
			return pushRouter('ListPromotions')
		}

		const sales = async ()=>{
			return pushRouter('Sales')
		}

		const debt = async ()=>{
			return pushRouter('DebtReport')
		}

		const debts = async ()=>{
			return pushRouter('DebtReport')
		}

		const ordersDebt = async ()=>{
			return pushRouter('OrdersDebt')
		}

		const comissions = async()=>{
			return pushRouter('Comissions')
			// const externalUrl = 'http://oneiric.online';
  			// return window.open(externalUrl, '_blank');
		}
		
		const priceList = async()=>{
			return pushRouter('PriceList')
			// const externalUrl = 'http://oneiric.online/precios';
  			// return window.open(externalUrl, '_blank');
		}
		
		const adminReports = async()=>{
			const externalUrl = 'http://oneiric.online/admin';
  			return window.open(externalUrl, '_blank');
		}
		
		const tridimensionalReport = async()=>{
			return pushRouter('TridimensionalReport')
		}

		const paymentReport = async()=>{
			return pushRouter('PaymentsReport')
		}

		const dashboardReports = async()=>{
			return pushRouter('Home')
		}


		const processMultiplePayments = async()=>{
			return pushRouter('ProcessMultiplePayments')
		}
		
		const inventoryReport = async()=>{
			return pushRouter('InventoryReport')
		}
		
		const payableAccounts = async()=>{
			return pushRouter('ClientsPayableOrdersReport')
		}
		
		const NAVBAR_ITEMS = computed(() => {
			const adminMenuPermissions = [1001,1002,1003]
			const productsMenuPermissions = [3000,3001,3002,3003,3004]
			const clientsMenuPermissions = [2001,2003,2004,2005,2006,2007,2008,2009,2010,2011]
			const reportsMenuPermissions = [1004,1005,1006,1007,1008]
			const productMenuActivate = productsMenuPermissions.some(seachPermit => userPermissions.includes(seachPermit));
			const adminMenuActivate = adminMenuPermissions.some(seachPermit => userPermissions.includes(seachPermit));
			const reportsMenuActivate = reportsMenuPermissions.some(seachPermit => userPermissions.includes(seachPermit));

			const filteredItems = [
				...(adminMenuActivate || adminType == 11 ? [{ 
					text: 'Admin',
					items: [						
						...( adminType == 11 ? [{ text: 'Promociones', onClick: promotions }] : []),
						// ...( adminType >= 10 ? [{ text: 'Categorías de gastos', onClick: expenseCategories }] : []),
						...( adminType >= 10 ? [{ text: 'Dashboard', onClick: dashboardReports }] : []),
						...(userPermissions.includes(1001) || adminType == 11 ? [{ text: 'Usuarios', onClick: users }] : []),
						...(adminType == 11 ? [{ text: 'Reporte tridimensional', onClick: tridimensionalReport }] : []),
						...(adminType == 11 ? [{ text: 'Reporte de pagos', onClick: paymentReport }] : []),
						...(userPermissions.includes(1009) || adminType == 11 ? [{ text: 'Conciliador de pagos', onClick: processMultiplePayments }] : []),
						// ...(adminType == 11 ? [{ text: 'Gastos', onClick: balanceReport }] : []),
					],
				}] : []),		
				...([{ 
					text: 'Ventas',
					items: [				
						...(userPermissions.includes(2001) || adminType == 11 ? [{ text: 'Clientes', onClick: clients }] : []),
						...(userPermissions.includes(2003) || adminType == 11 ? [{ text: 'Clientes por aceptar', badge: count.value, onClick: clientProspects }] : []),
						...(userPermissions.includes(2004) || userPermissions.includes(2008) || adminType == 11 ? [{ text: 'Pedidos', onClick: clientOrders }] : []),
						...(userPermissions.includes(2005) || adminType == 11 ? [{ text: 'Grupos', onClick: groups }] : []),
						...(userPermissions.includes(2006) || adminType == 11 ? [{ text: 'Rutas', onClick: routes }] : []),
						...(userPermissions.includes(2007) || adminType == 11 ? [{ text: 'Segmentos', onClick: clientTypes }] : []),
						...(userPermissions.includes(2011) || adminType == 11 ? [{ text: 'Mapa de clientes', onClick: clientsSalesMap }] : []),														
						...(userPermissions.includes(1006) || adminType == 11 ? [{ text: 'Reporte de ventas por producto', onClick: sales }] : []),
						...(userPermissions.includes(1005) || userPermissions.includes(1009) || adminType == 11 ? [{ text: 'Reporte de cuentas por cobrar', onClick: payableAccounts }] : []),
						...(userPermissions.includes(1004) || userPermissions.includes(1007) || adminType == 11 ? [{ text: 'Reporte de clientes', onClick: sellerReport }] : []),
						...(userPermissions.includes(1005) || userPermissions.includes(1009) || adminType == 11 ? [{ text: 'Reporte de cobranzas', onClick: debts }] : []),
					],
				}]),
				...(productMenuActivate || adminType == 11 ? [	{
					text: 'Productos',
					items: [
						...(userPermissions.includes(3000) || adminType == 11 ? [{ text: 'Productos', onClick: products }] : []),
						...(userPermissions.includes(3001) || adminType == 11 ? [{ text: 'Listas de Rango de Precio', onClick: ranges }] : []),
						...(userPermissions.includes(3002) || adminType == 11 ? [{ text: 'Lineas de Producto', onClick: productTypes }] : []),
						...(userPermissions.includes(3003) || adminType == 11 ? [{ text: 'Unidades', onClick: productPresentations }] : []),
						...(userPermissions.includes(3004) || adminType == 11 ? [{ text: 'Lista de precios', onClick: priceList }] : []),					
					],
				}]:[]),
				...(userPermissions.includes(4000) || adminType == 11 ? [{ text: 'Repartos', onClick: runs }] : []),
				...(userPermissions.includes(5000) || adminType == 11 ? [{ 
					text: 'Almacenes', 
					items: [
						{ text: 'Almacenes',onClick: warehouses },
						{ text: 'Reporte de inventarios', onClick: inventoryReport },
					]
				,}]: []),
				...(userPermissions.includes(6000) || adminType == 11 ? [{
					text: 'Proveedores',
					items: [
						{ text: 'Proveedores', onClick: suppliers },
						...(userPermissions.includes(6001) || adminType == 11 ? [{ text: 'Ordenes de compra', onClick: orders }] : []),
					],
				}]:[]),
			];

			return filteredItems.filter(obj => {
				return true;
			});
		});

		const toggleSidebar = ()=>{
			toggleSidebarP(NAVBAR_ITEMS.value)
		}

		return {
			clientOrders,
			clientProspects,
			clientTypes,
			clients,
			comissions,
			debts,
			groups,
			home,
			logout,
			orders,
			ordersDebt,
			productPresentations,
			products,
			productTypes,
			ranges,
			routes,
			runs,
			sellerClients,
			suppliers,
			userName,
			debt,
			toggleSidebar,
			users,
			warehouses,
			count,
			NAVBAR_ITEMS,
			promotions,
			promotionsList,
			promotionsCreate,
			sales,
			sellerReport,
			adminType,
			userPermissions,
			priceList,
			adminReports,
			clientsSalesMap,
			createOrder,
			inventoryReport,
			payableAccounts


		}
	}
}
</script>

<style scoped>
	.ui.menu .item.mobile{
		display: none;
	}
	@media screen and (max-width: 800px){
		.ui.menu .item.mobile{
			display: block;
		}
		.ui.menu .item.desktop{
			display: none;
		}
	}
</style>