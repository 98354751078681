import { createRouter, createWebHashHistory } from 'vue-router'
import isAuthenticated from '../auth/authGuard'
import isAuthenticatedLogin from '../auth/authLogin'
import isAuthType from '../auth/authGuardAccess'

const routes = [
	{
		path: "/login",
		name: 'Login',
		beforeEnter: [isAuthenticatedLogin],
		component: ()=> import('../users/views/Login'),
		meta: {
			hideNavbar: true,
		}
	},
	{
		path: '/home',
		name: 'Home',
		beforeEnter: [isAuthenticated],
		component: ()=> import('../users/views/Home'),
	},
	{
		path: '/users/alta',
		name: 'CreateUser',
		beforeEnter: [isAuthType],
		component: ()=> import('../users/components/Register'),
	},
	{
		path: '/users',
		name: 'Users',
		beforeEnter: [isAuthenticated],
		component: ()=> import('../users/views/Users'),
		meta: {
			title: 'Cebada'
		}
	},
	{
		path: '/users/:id',
		name: 'User',
		beforeEnter: [isAuthenticated],
		component: ()=> import('../users/views/Profile'),
		props: ( route ) => {
			const id = Number( route.params.id );
			return isNaN( id ) ? { id: localStorage.getItem('idSearch') } : { id }
		},
	},
	{
		path: '/users/:id/incentives',
		name: 'UserIncentives',
		beforeEnter: [isAuthenticated],
		component: ()=> import('../users/views/UserIncentives'),
		props: ( route ) => {
			const id = Number( route.params.id );
			return isNaN( id ) ? { id: localStorage.getItem('idSearch') } : { id }
		},
	},
	{
		path:'/user/clients',
		name: 'SellerClients',
		beforeEnter: [isAuthenticated],
		component: ()=> import('../sellers/views/Clients')
	},
	{
		path: '/clients',
		name: 'Clients',
		beforeEnter: [isAuthType],
		component: ()=> import('../clients/views/Clients')
	},
	{
		path: '/segmentos',
		name: 'ClientTypes',
		beforeEnter: [isAuthType],
		component: ()=> import('../clients/views/Types')
	},
	{
		path: '/clientes/registro',
		name: 'RegisterClient',
		beforeEnter: [isAuthType],
		component: ()=> import('../clients/components/Register')
	},
	{
		path: '/clients/prospects',
		name: 'ClientsProspects',
		beforeEnter: [isAuthType],
		component: ()=> import('../clients/views/Prospects')
	},
	{
		path: '/clients/:id',
		name: 'ClientProfile',
		beforeEnter: [isAuthType],
		component: ()=> import('../clients/components/Client'),
		props: ( route ) => {
			const id = Number(route.params.id);
			return isNaN(id) ? { id: localStorage.getItem('client_id') } : { id }
		},
	},
	{
		path: '/clients/:id/incentives',
		name: 'ClientIncentives',
		beforeEnter: [isAuthType],
		component: ()=> import('../clients/views/ClientIncentives'),
		props: ( route ) => {
			const id = Number(route.params.id);
			return { id }
		},
	},
	{
		path: '/clients/groups',
		name: 'Groups',
		beforeEnter: [isAuthType],
		component: ()=> import('../clients/views/Groups')
	},
	{
		path: '/clients/map',
		name: 'ClientsSalesMap',
		beforeEnter: [isAuthType],
		component: ()=> import('../clients/views/ClientsSalesMap')
	},
	{
		path: '/rangos',
		name: 'Ranges',
		beforeEnter: [isAuthType],
		component: ()=> import('../prices/Prices')
	},
	{
		path: '/ranges/:range_id',
		name: 'Range',
		beforeEnter: [isAuthType],
		component: () => import('../prices/Price.vue'),
		props: ( route ) => {
			const range_id = Number( route.params.range_id );
			return {range_id} 
		} 
	},
	{
		path: '/groups/:id',
		name: 'Group',
		beforeEnter: [isAuthType],
		component: ()=> import('../clients/views/Group.vue'),
		props: (route) => {
			return { group_id: route.params.id };
		},
	},
	{
		path: '/routes',
		name: 'Routes',
		beforeEnter: [isAuthType],
		component: () => import('../client_routes/views/Routes') 
	},
	{
		path: '/routes/:route_id',
		name: 'Route',
		beforeEnter: [isAuthType],
		component: () => import('../client_routes/views/Route'),
		props: ( route ) => {
			const route_id = Number( route.params.route_id );
			return {route_id} 
		} 
	},
	{
		path: '/clients/orders',
		name: 'ClientsOrders',
		beforeEnter: [isAuthType],
		component: () => import('../sellers/views/ClientsOrders')
	},
	{
		path: '/clients/orders/:order_id',
		name: 'ClientOrderView',
		beforeEnter: [isAuthType],
		component: () => import('../sellers/views/ClientOrderView'),
		props: ( route ) => {
			const order_id = Number( route.params.order_id );
			return {order_id} 
		} 
	},
	{
		path:'/clientes/ventas',
		name:'Sales',
		beforeEnter: [isAuthType],
		component: () => import('../clients/views/Sales'),
	},
	{
		path: '/clients/order/create',
		name: 'CreateOrder',
		beforeEnter: [isAuthType],
		component: () => import('../sellers/views/CreateOrder'),
		props: ( route ) => {
			const business_id = Number( route.params.business_id );
			return { business_id } 
		} 
	},
	{
		path: '/products',
		name: 'Products',
		beforeEnter: [isAuthenticated],
		component: ()=> import('../products/views/Products'),
	},
	{
		path: '/products/price-list',
		name: 'PriceList',
		beforeEnter: [isAuthenticated],
		component: ()=> import('../products/views/PriceList'),
	},
	{
		path: '/presentaciones',
		name: 'ProductUnits',
		beforeEnter: [isAuthenticated],
		component: ()=> import('../products/views/Units')
	},
	{
		path: '/Productos/Lineas-de-producto',
		name: 'ProductTypes',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../products/views/Types'),
	},
	{
		path:'/admin/promotions/',
		name: 'Promotions',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../promotions/Promotions'),
	},
	{
		path:'/admin/promotions/create',
		name: 'CreatePromotions',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../promotions/createPromotion'),
	},
	{
		path:'/admin/promotions/list',
		name: 'ListPromotions',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../promotions/listPromotions'),
	},
	{
		path: '/products/alta',
		name: 'AltaProduct',
		beforeEnter: [isAuthenticated],
		component: ()=> import('../products/components/Register')
	},
	{
		path: '/products/:id',
		name: 'Producto',
		beforeEnter: [isAuthenticated],
		component: ()=> import('../products/views/Product'),
		props: ( route ) => {
			const id = Number( route.params.id );
			return isNaN( id ) ? { id: localStorage.getItem('product_id') } : { id }
		},

	},
	{
		path: '/almacenes',
		name: 'Warehouses',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../warehouses/views/Warehouses')
	},
	{
		path: '/almacenes/:id',
		name: 'Almacen',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../warehouses/views/Warehouse'),
		props: ( route ) => {
			const id = Number( route.params.id );
			return isNaN( id ) ? { id: localStorage.getItem('warehouse_id') } : { id }
		},
	},
	{
		path: '/almacenes/alta',
		name: 'AltaAlmacenes',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../warehouses/components/Register')
	},
	{
		path: '/almacenes/secciones/info',
		name: 'SeccionInfo',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../warehouses/components/SectionContent')
		
	},
	{
		path: '/almacenes/inventario/reporte',
		name: 'InventoryReport',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../reports/views/InventoryReport')
		
	},

	{
		path: '/otros',
		name: 'Others',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../users/components/rangeCreate')

	},
	{
		path: '/proveedores',
		name: 'Suppliers',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../suppliers/views/Suppliers')
	},
	{
		path: '/proveedores/registro',
		name: 'SupplierRegister',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../suppliers/components/Register')
	},
	{
		path: '/proveedor/:id',
		name: 'Supplier',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../suppliers/views/Supplier'),
		props: ( route ) => {
			const id = Number( route.params.id );
			return isNaN( id ) ? { id: localStorage.getItem('supplierId') } : { id }
		},
	},
	{
		path: '/ordenes',
		name: 'Orders',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../orders/views/Orders')
	},
	{
		path: '/ordenes/:id',
		name: 'OrderView',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../orders/components/Order'),
		props: ( route ) => {
			const id = Number( route.params.id );
			return isNaN( id ) ? { id: localStorage.getItem('order_id') } : { id }
		},
	},
	{
		path: '/repartos',
		name: 'Runs',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../runs/views/Runs.vue')
	},
	{
		path: '/repartos/:run_id',
		name: 'Run',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../runs/views/RunView.vue'),
		props: (route)=>{
			const run_id = Number(route.params.run_id);
			return { run_id } 
		} 
	},
	{
		path: '/repartos/crear/:route_id',
		name: 'CreateRun',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../runs/views/CreateRun.vue'),
			props: (route)=>{
				return { route_id: route.params.route_id };
			}
	},
		{
		path: '/vendedores/reporte',
		name: 'SellerReport',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../sellers/views/SellerReport.vue')
	},
		{
		path: '/vendedores/deuda',
		name: 'SellerDebt',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../sellers/views/Debt.vue')
	},
		{
		path: '/vendedores/comisiones',
		name: 'Comissions',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../sellers/views/Comissions.vue')
	},
		{
		path: '/cobranza',
		name: 'DebtReport',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../sellers/views/DebtReport.vue')
	},
		{
		path: '/cobranza/pedidos',
		name: 'OrdersDebt',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../sellers/views/OrdersDebtReport.vue')
	},
	{
		path: '/reportes/tridimensional',
		name: 'TridimensionalReport',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../reports/views/TridimensionalReport.vue')
	},
	{
		path: '/reportes/pagos',
		name: 'PaymentsReport',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../reports/views/PaymentsReport.vue')
	},
	{
		path: '/clients/reports/cc',
		name: 'ClientsPayableOrdersReport',
		beforeEnter: [isAuthenticated],
		component: () => import('../reports/views/ClientsPayableOrdersReport.vue'),
	},
	{
		path: '/admin/conciliador',
		name:'ProcessMultiplePayments',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../orders/views/ProcessMultiplePayments.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		beforeEnter: [isAuthenticated],
		component: ()=>import('../users/views/Home'),
	}

]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior() {
		document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router;