import axios from 'axios';
import moment from 'moment';
import router from '../routes/routes';
	
const API_URL = process.env.VUE_APP_API_URL
const DEBUG = process.env.VUE_APP_DEBUG || false;
axios.defaults.withCredentials = true;

/**
 * @typedef User
 * @property {number} admin_id
 */

/**
 * @typedef PostData
 * @property {Object} data
 * @property {boolean} error
 * @property {string} message
 * @property {string} message_client
 * @property {User} user
 */

/**
 * Post to the API
 * @param {string} endpoint 
 * @param {Object} data 
 * @returns {PostData}
 */
const post = async (endpoint, data)=>{	
	var options = {}
	try{
		var api_key = localStorage.getItem('apiKey');
		if(api_key){
			options.headers = {
				token: api_key
			}
		}
	}catch(e){
		if(DEBUG) console.log('Error getting api key from localStorage', e);
	}	
	try{
		var ENDPOINT = `${API_URL}/${endpoint}`;
		if(DEBUG) console.log("POST "+ENDPOINT, JSON.stringify(data, null, 3), options);
		var res = await axios.post(ENDPOINT, data, options);
		// console.log('Response', res.data);
		if(res.status==429){
			return { error: true, message: 'Too many attempts', message_client: 'Hay muchos intentos en el sistema, intena de nuevo mas tarde.' }
		}
		if(res.data && res.data.error && res.data.code==2000){
			localStorage.removeItem('apiKey');
			router.push('/login');
			return { error: true, message_client: 'Sesión cerrada por inactividad.', message: 'Sesión cerrada por inactividad.' };
		}		
		if(res.data?.token){ // If the response has a new token, update the local storage
			if(localStorage.getItem('apiKey') == res.data.token) return res.data;
			localStorage.removeItem('apiKey');
			localStorage.setItem("apiKey", res.data.token);			
		}
		return res.data;
	}catch(err){
		if(DEBUG) console.log(err);
		if(err && err.message && err.message.toLowerCase().indexOf('network error')>=0){
			return { error: true, message: 'Request timeout reached.', message_client: 'No hay conexión al sistema (A-LCLT-1).' }
		}
		if(err && err.message && err.message.indexOf('timeout')>=0){
			return { error: true, message: 'Request timeout reached.', message_client: 'No hay conexión al sistema (A-LCLT-2).' }
		}
		if(err && err.response && err.response.status && err.response.status==429){
			return { error: true, message: 'Too many attempts', message_client: 'Hay muchos intentos en el sistema, intena de nuevo mas tarde. (A-LCLT-3)' }
		}
		if(DEBUG) console.log('error', err);
		return {
			error: true, message: 'Unexpected error (LCL-1)', message_client: 'Hubo un error inesperado con la comunicación al sistema. (A-LCLT-4)'
		}
	}
}

const download = async (name, endpoint, data)=>{
	var res = await post(endpoint, data);
	const blob = new Blob([res], { type: 'text/csv' })
	const link = document.createElement('a')
	link.href = URL.createObjectURL(blob)
	link.download = name;
	link.click()
	URL.revokeObjectURL(link.href)
}

const Login = async (email, password)=>{
	return post('login', { email, password });
}

const logout = async ()=>{
	return post('logout', {});
}

const listProducts = async (only_deleted=false)=>{
	return post('products/list', {
		only_deleted: only_deleted ? 1 : 0
	});
}

const listPriceProducts = async (only_deleted=false)=>{
	return post('products/list/price', {
		only_deleted: only_deleted ? 1 : 0
	});
}

const listMissingProducts = async()=>{
	return post('products/list/missing');
}

const createRoute = async (route_name, route_description, warehouse_id, daily_limit, weekdays)=>{
	return post('routes/create', {
		route_name,
		route_description,
		warehouse_id,
		daily_limit,
		weekdays
	})
}

const deleteRoute = async (route_id)=>{
	return post('routes/delete', { route_id });
}

const activateRoute = async (route_id)=>{
	return post('routes/activate', { route_id })
}

const activeRoutes = async ()=>{
	return post('warehouses/routes/pending');
}

const getRoutePendingOrders = async (route_id)=>{
	return post('routes/orders/pending', { route_id });
}

const getRoutes = async ()=>{
	return post('routes/list');
}

const getRoute = async (route_id)=>{
	return post('routes/get', { route_id });
}

const getRouteClients = async (route_id)=>{
	return post('routes/clients', { route_id });
}

const deleteClientRoute = async (business_id)=>{
	return post('clients/route/delete', { business_id });
}

const getNoRouteClients = async ()=>{
	return post('routes/get/noRouteClients');
}

const updateSupplier = async (supplier_id, supplier_name, payment_days)=>{
	return post('suppliers/supplier/update', {
		supplier_id,
		supplier_name,
		payment_days
	});
}

const addClientRoute = async (route_id, business_id)=>{
	return post('clients/route/add', {
		route_id,
		business_id,
	});
}

const getDeletedRoutes = async ()=>{
	return post('routes/inactives');
}

const changeClientRoute = async (business_id, route_id)=>{
	return post('clients/route/edit', {
		route_id,
		business_id
	});
}

const getRouteWeekDays = async (route_id)=>{
	return post('routes/get/weekdays', { route_id });
}

const getClientsOrders = async (offset=0, count=50, cancelled, date_from, date_to)=>{
	return post('clients/orders/get', { offset, count, cancelled, date_from, date_to });
}

const updateRoute = async (route_id, route_name, route_description, daily_limit, warehouse_id, weekdays)=>{
	return post('routes/edit', {
		route_id,
		route_name,
		route_description,
		daily_limit,
		warehouse_id,
		weekdays,
	});
}

const getClientOrderDetails = async (order_id, products=true, payments=false, attempts=false, taxdata=false, invoice=false)=>{
	return post('clients/order/get', {
		order_id,
		products: products ? 1 : 0,
		payments: payments ? 1 : 0,
		attempts: attempts ? 1 : 0,
		taxdata: taxdata ? 1 : 0,
		invoice: invoice ? 1 : 0,
	}); 
}

const getClientTaxdata = async (business_id)=>{
	return post('clients/taxdata', { business_id });
}

const getClientRouteDays = async ( order_id )=>{
	return post('clients/route/days/get', { order_id });
}

const getSatCodes = async (query)=>{
	return post('products/codes', { query });
}

const getProductAvailability = async (product_id)=>{
	return post('products/stock/verify', { product_id });
}

const checkCartExistence = async (cart)=>{
	return post('orders/order/confirmCartItems', { cart });
}

const getBusinessInfo = async (business_id)=>{
	return post('clients/info/get', { business_id });
}

const getClientCredit = async (business_id)=>{
	return post('clients/credit/status/get', { business_id });
}

const getClientContacts = async (business_id)=>{
	return post('clients/contacts/get', { business_id });
}

const getContactInfo = async (contact_id)=>{
	return post('contact/info/get', { contact_id });
}

const createOrder = async (orderData)=>{
	return post('orders/bussines/create', { orderData });
}

const sendEmail = async ()=>{
	return post('clients/sendMail');
}

const getWarehouses = async ()=>{
	return post('Warehouses/list');
}

const getClientRouteWeekdays = async (business_id)=>{
	return post('clients/weekdays/get', { business_id });
}

const getActiveRuns = async ()=>{
	return post('routes/run/list');
}

const getUsers = async ()=>{
	return post('users/list');
}

const getSellers = async ()=>{
	return post('users/sellers');
}

const getUsersWithAccess = async (access, single)=>{
	return post('users/list/access', {
		access, single: single ? 1 : 0
	});
}

const getRunDetails = async (run_id)=>{
	return post('routes/run/get', { run_id });
}

const registerWarehouse = async (name, description, address, sections)=>{
	return post('warehouses/register', {
		name,
		description,
		address,
		sections,
	});
}

const getPriceRanges = async ()=>{
	return post('ranges/list');
}

const createRange = async (rangeData)=>{
	return post('ranges/create', {
		...rangeData
	});
}

const getRange = async (range_id, clients=0, groups=0)=>{
	return post('ranges/get', { range_id, clients, groups })
}

// const getRangeClients = async (range_id)=>{
// 	return post('ranges/clients', { range_id });
// }

// const getRangeGroups = async (range_id)=>{
// 	return post('ranges/groups', { range_id });
// }

const updatePriceRange = async (range_id, range_name, discount, individual_min, group_min, money_min, group_money_min)=>{
	return post('ranges/edit', {
		range_id, range_name, discount,
		individual_min,
		group_min,
		money_min,
		group_money_min,
	});
}

const getProduct = async (product_id, images=0, prices=1, metadata=0)=>{
	return post('products/get', { product_id, images, prices, metadata });
}

const editProductMetadata = async (product_id, metadata_id, value)=>{
	return post('products/edit/metadata', { product_id, metadata_id, value });
}

const createSupplier = async ( supplier_name, expDays,  contact )=>{
	return post('suppliers/register', {
		supplier_name,
		expDays,
		contact
	});
}

const getProductTypes = async ()=>{
	return post('products/types/get');
}

const getDeletedProductTypes = async ()=>{
	return post('products/types/deleted/get');
}

const editClientType = async (type_name, type_id)=>{
	return post('clients/types/edit', {
		type_name,
		type_id
	});
}

const getSuppliers = async ()=>{
	return post('suppliers/list');
}

const createProduct = async (product_data)=>{
	return post('products/create', product_data);
}

const getAdminTypes = async ()=>{
	return post('users/types/get');
}

const createUser = async (first_name, last_name, email, password, admin_type, phone)=>{
	return post('users/create', {
		first_name, last_name, email, password, admin_type, phone
	});
}

const getUser = async (admin_id)=>{
	return post('users/get', { admin_id });
}

const getClientDiscounts = async (client_id)=>{
	return post('clients/ranges/get', { client_id });
}

const getGroupInfo = async (group_id, members=false)=>{
	return post('clients/groups/get', {
		group_id,
		members: members ? 1 : 0
	});
}

const getGroupMembers = async (group_id)=>{
	return post('clients/groups/members/get', { group_id });
}

const getNotGroupedBusiness = async (group_id)=>{
	return post('clients/groups/missing', { group_id });
}

const deleteGroup = async (group_id)=>{
	return post('clients/groups/delete', { group_id });
}

const activateGroup = async (group_id)=>{
	return post('clients/groups/activate', { group_id });
}

const addGroupMember = async (group_id, business_id)=>{
	return post('clients/groups/member/add', {
		business_id,
		group_id
	});
}

const removeGroupMember = async (group_id, business_id)=>{
	return post('clients/groups/member/remove', {
		business_id,
		group_id
	});
}

const getUserPermissions = async ()=>{
	return post('users/permissions/me');
}

const getAllPermissions = async (categories=false)=>{
	return post('users/permissions/list', {
		categories: categories ? 1 : 0
	});
}

const getPermissionsCategories = async ()=>{
	return post('users/permissions/categories');
}

const addUserPermissions = async (admin_id, permissions)=>{
	return post('users/permissions/add', {
		admin_id,
		permissions
	});
}

const updateUser = async (admin_id, first_name, last_name, email, admin_type, phone, commission, warehouse_id, password)=>{
	return post('users/edit', {
		admin_id,
		first_name,
		last_name,
		email,
		admin_type,
		phone,
		commission,
		warehouse_id,
		password
	});
}

const supplierContacts = async (supplier_id)=>{
	return post('suppliers/contacts/get', { supplier_id });
}

const addSupplierContact = async (supplier_id, contact)=>{
	return post('suppliers/contacts/register', {
		supplier_id,
		contact
	});
}

const editSupplierContact = async (contactData)=>{
	return post('suppliers/contact/update', {
		contactData
	});
}

const editSupplier = async (supplierData)=>{
	return post('suppliers/supplier/update', {
		supplierData
	});
}

const getSupplier = async (supplier_id)=>{
	return post('suppliers/get', { supplier_id });
}

const getInventorySupplierProducts = async (supplier_id)=>{
	return post('products/supplier/products', { supplier_id });
}

const getSupplierProducts = async (supplier_id)=>{
	return post('products/supplier/products/get', { supplier_id });
}


const getSupplierOrders = async ( supplier_id, offset=0, count=50, cancelled )=>{
	return post('orders/supplier/get', { supplier_id, offset, count, cancelled });
}

const getProductExistence = async (product_id)=>{
	return post('products/quantity/get', { product_id });
}

const getProductInSections = async (product_id)=>{
	return post('products/sections/get', { product_id });
}

const getWarehouse = async (warehouse_id)=>{
	return post('warehouses/get', { warehouse_id });
}

const updateWarehouse = async (warehouseData)=>{
	return post('warehouses/update', {
		warehouseData
	});
}

const getWarehouseSections = async (warehouse_id)=>{
	return post('warehouses/sections/get', { warehouse_id });
}

const createWarehouseSection = async (warehouseData)=>{
	return post('warehouses/section/create', {
		warehouseData
	});
}

const getSectionProducts = async (section_id)=>{
	return post('warehouses/section/products', { section_id });
}

const addProductInventory = async ( product_id, quantity, section_id, batch, expiration_date)=>{
	return post('warehouses/inventory/add', {
		product_id,
		quantity,
		section_id,
		batch,
		expiration_date
	});
}

const updateProduct = async (product_id, product_data)=>{
	return post('products/edit', {
		product_id,
		...product_data
	});
}

const updateProductDescription = async (product_id, description)=>{
	return post('products/edit/description', { product_id, ...description });
}

const createProductType = async (type_name)=>{
	return post('products/types/create', { type_name });
}

const editProductType = async (type_id, type_name)=>{
	return post('products/types/edit', {
		type_id,
		type_name,
	});
}

const getOrders = async ( offset=0, count=50, cancelled )=>{
	return post('orders/list', { offset, count, cancelled  });
}

const getOrder = async (order_id, products, payments)=>{
	return post('orders/get', {
		order_id,
		products,
		payments
	});
}

const registerClient = async (info, location, credit, contact)=>{
	return post('clients/create', {
		info, location, credit, contact
	});
}

const deleteUser = async (admin_id)=>{
	return post('users/delete', { admin_id });
}

const getInactiveUsers = async ()=>{
	return post('users/inactive');
}

const activateUser = async (admin_id)=>{
	return post('users/activate', { admin_id });
}

const getActiveClients = async (cancelled = 0)=>{
	return post('clients/active', { cancelled });
}

const getSellerActiveClients = async(seller_id)=>{
	return post('clients/seller/actives', { seller_id });
}

const getClientsWithSales = async(seller_id = null, date_from = null, date_to = null)=>{
	return post('clients/withSales', { seller_id, date_from, date_to });
}

const getInactiveClients = async ()=>{
	return post('clients/inactive');
}

const getActiveCustomersTrend = async (seller_id = null)=>{
	return post('clients/trend', { seller_id });
}

const approveClient = async (business_id, credit_limit, credit_orders, credit_deadline, delivery_route)=>{
	return post('clients/approve', {
		business_id,
		credit_limit, 
		credit_orders, 
		credit_deadline,
		delivery_route,
	});
}

const getClientInfo = async (business_id, contacts=false, balance=false, events=false, taxData=false, credit=false, allTaxData=false, location=false, orders=false)=>{
	return post('clients/get', {
		business_id, 
		contacts: contacts ? 1 : 0,
		balance: balance ? 1 : 0,
		events: events ? 1 : 0,
		taxData: taxData ? 1 : 0,
		credit: credit ? 1 : 0,
		allTaxData: allTaxData ? 1 : 0,
		location: location ? 1 : 0,
		orders: orders ? 1 : 0
	});
}

const deleteSupplier = async (supplier_id)=>{
	return post('suppliers/delete', { supplier_id });
}

const activateSupplier = async (supplier_id)=>{
	return post('suppliers/activate', { supplier_id });
}

const getDeletedSuppliers = async ()=>{
	return post('suppliers/deleted');
}

const getActiveWarehouses = async ()=>{
	return post('warehouses/list');
}

const getInactiveWarehouses = async ()=>{
	return post('warehouses/inactive');
}

const deleteWarehouse = async (warehouse_id)=>{
	return post('warehouses/delete', { warehouse_id });
}

const activateWarehouse = async (warehouse_id)=>{
	return post('warehouses/activate', { warehouse_id });
}

const getActiveGroups = async ()=>{
	return post('clients/groups/active');
}

const getDeletedGroups = async ()=>{
	return post('clients/groups/inactive');
}

const createGroup = async (group_name)=>{
	return post('clients/groups/create', { group_name });
}

const editClientCredit = async (business_id, credit_limit, credit_orders, credit_deadline)=>{
	return post('clients/credit/update', {
		business_id,
		credit_limit,
		credit_orders,
		credit_deadline,
	});
}

const updateClient = async (business_id, client_data)=>{
	return post('clients/edit', {
		business_id,
		...client_data,
	});
}

const changeBusinessType = async (business_id, type_id)=>{
	return post('clients/edit/type', {
		business_id,
		type_id,
	});
}

const deleteClient = async (business_id)=>{
	return post('clients/delete', { business_id });
}

const activateClient = async (business_id)=>{
	return post('clients/activate', { business_id });
}

const getPendingClientCount = async ()=>{
	return post('pending/count');
}

const PendingClients = async ()=>{
	return post('clients/pending');
}

const getUnits = async ()=>{
	return post('products/units');
}

const createUnit = async (unit_name)=>{
	return post('products/units/create', { unit_name });
}

const getProductPresentations = async (product_id, cancelled)=>{
	return post('products/presentations', { product_id, cancelled });
}

const createPresentation = async (product_id, presentation_name, inventory_amount, unit_id, shipping_points)=>{
	return post('products/presentation/create', {
		product_id,
		presentation_name,
		inventory_amount,
		unit_id,
		shipping_points
	});
}

const updateGroup = async (group_id, group_name)=>{
	return post('clients/groups/update', {
		group_id,
		group_name
	});
}

const getInactivesPrices = async ()=>{
	return post('ranges/inactives');
}

const deleteRange = async (range_id)=>{
	return post('ranges/delete', { range_id });
}

const activateRange = async (range_id)=>{
	return post('ranges/activate', { range_id });
}

const getClientTypes = async ()=>{
	return post('clients/types');
}

const createClientType = async (type_name)=>{
	return post('clients/types/create', { type_name });
}

const createRun = async (route_id, orders, delivery_user)=>{
	return post('routes/run/create', {
		route_id, orders, delivery_user
	});
}

const createClientContact = async (business_id, contact_data)=>{
	return post('clients/contact/create', {
		business_id,
		...contact_data
	});
}
const updateClientContact = async (business_id, contact_id, contact_data)=>{
	return post('clients/contact/edit', {
		contact_id,
		business_id,
		...contact_data
	});
}

const updateSupplierContact = async (contact)=>{
	return post('suppliers/contact/update', {
		contact
	});
}

const createSupplierOrder = async(supplier_id, total, cart, creditDays)=>{
	return post('suppliers/order/create', {
		supplier_id,
		total,
		cart,
		creditDays
	});
}

const createInvoice = async (order_id, rfc_id, rfc_emiter)=>{
	return post('clients/order/sat/generate', {
		order_id,
		rfc_id,
		rfc_emiter
	});
}

const cancelInvoice = async (order_id)=>{
	return post('clients/order/sat/cancel', { order_id });
}

const downloadInvoice = async (invoice_id, complete=false)=>{
	return post('clients/order/sat/download', {
		invoice_id,
		complete: complete ? 1 : 0
	});
}

const downloadInvoiceComplement = async (complement_id, complete=false, order_id)=>{
	return post('clients/order/sat/complement/download', {
		complement_id,
		complete: complete ? 1 : 0,
		order_id
	});
}

const getPaymentMethods = async (manual=false)=>{
	return post('clients/order/methods', { manual: manual ? 1 : 0 });
}

const setOrderPaid = async (order_id, amount_paid, payment_method, date_paid, reference)=>{
	return post('clients/order/paid', { order_id, payment_method, reference, amount_paid, date_paid });
}

const setClientSeller = async (business_id, admin_id)=>{
	return post('clients/edit/seller', {
		business_id,
		admin_id
	})
}

const createClientCredit = async (business_id, amount, comments)=>{
	return post('clients/credit/create', {
		business_id, amount, comments
	})
}

const getCreditLog = async (business_id)=>{
	return post('clients/credit/log', { business_id });
}

const cancelCredit = credit_id=>{
	return post('clients/credit/cancel', { credit_id });
}

const addProductToInventory = async(product_id, amount, section_id, batch, expTime)=>{
	return post('products/inventory/add', { product_id, amount, section_id, batch, expTime })
}

const uploadProductImage = async (product_id, data)=>{
	return post('products/image/add', { product_id, data });
}

const deleteProductImage = async (product_id, url)=>{
	return post('products/image/delete', { product_id, url });
}

const setPrimaryImageProduct = async (product_id, url)=>{
	return post('products/image/primary', { product_id, url });
}

const editProductUnit = async(unit_id, unit_name)=>{
	return post('products/units/edit', { unit_id, unit_name });
}

const editProductPrice = async (product_id, range_id, discount)=>{
	return post('products/price/edit', { product_id, range_id, discount });
}

const getSellerClientsInfo = async()=>{
	return post('clients/seller/info');
}

const getSellerInactiveClients = async()=>{
	return post('clients/seller/inactives');
}
const updateClientLocation = async(location, business_id)=>{
	return post('clients/location/update', {location, business_id});
}

const stockRevision = async(product_id, quantity)=>{
	return post('products/stock',{product_id, quantity});
}

const cancelOrder = async(order_id, cancel_comments)=>{
	return post('orders/cancel',{order_id, cancel_comments});
}

const getBusinessOrderInfo = async(business_id)=>{
	return post('clients/order/info/get',{business_id})
}

const changeClientRange = async( business_id, range_id )=>{
	return post('ranges/range/add',{ business_id, range_id })
}

const createClientOrder = async(business_id, contact_id, shipping_location, comments, rfc_id, date_estimate = moment().add(1, 'days').unix(), payment_method, use_credit, freeOrder, isQuote, emailExtra, cart)=>{	
	return post('clients/order/create', { business_id, contact_id, shipping_location, comments, rfc_id, date_estimate, payment_method, use_credit, freeOrder, isQuote, emailExtra, cart })
}

const createClientEvent = async(business_id, visit_type, user_visiter, date_visit )=>{
	return post('clients/event/create',{ business_id, visit_type, user_visiter, date_visit })
}

const deleteProduct = async(product_id)=>{
	return post('products/delete', { product_id })
}

const activateProduct = async(product_id)=>{
	return post('products/activate', { product_id })
}

const salesReport = async(fromDate, toDate)=>{
	return post('orders/sales/report', { fromDate, toDate });
}

const supplierSalesReport = async(fromDate, toDate)=>{
	return post('suppliers/sales/report', { fromDate, toDate });
};

const getUserIncentives = (admin_id, offset=0, count=5)=>{
	return post('users/incentives', { admin_id, user: 1, offset, count });
}

const createUserIncentive = (admin_id, sales, description, date_start, date_end)=>{
	return post('users/incentives/create', { admin_id, sales, description, date_start, date_end });
}

const editUserIncentive = (incentive_id, sales, description, date_start, date_end)=>{
	return post('users/incentives/edit', { incentive_id, sales, description, date_start, date_end });
}

const deleteUserIncentive = (incentive_id)=>{
	return post('users/incentives/delete', { incentive_id });
}

const groupSalesReport = async(fromDate, toDate, group_id)=>{
	return post('clients/group/sales/report', { fromDate, toDate, group_id });
}

const clientSalesReport = async(fromDate, toDate, business_id)=>{
	return post('clients/sales/report', { fromDate, toDate, business_id });
}

const clientTaxDataList = async(business_id)=>{
	return post('clients/taxinfo/list', { business_id });
}

const addClientTaxData = async(business_id, legal_name, email, rfc, regimen, use_cfdi, location)=>{
	return post('clients/taxinfo/add',{ business_id, legal_name, email, rfc, regimen, use_cfdi, location });
}

const cancelClientOrder = async(order_id)=>{
	return post('clients/order/cancel',{ order_id })
}

const getSupplierIncentives = async(supplier_id)=>{
	return post('suppliers/incentives/get', { supplier_id })
}

const getClientIncentives = async(business_id, offset=0, count=5)=>{
	return post('clients/incentives', { business_id, offset, count, client:1})
}

const createClientIncentive = (business_id, sales, description, date_start, date_end)=>{
	return post('clients/incentives/create', { business_id, sales, description, date_start, date_end });
}

const editClientIncentive = ( incentive_id, sales, description, date_start, date_end )=>{
	return post('clients/incentives/edit', { incentive_id, sales, description, date_start, date_end })
}

const deleteClientIncentive = (incentive_id)=>{
	return post('clients/incentives/delete', { incentive_id });
}

const warehouseInventoryReport = (fromDate, toDate, warehouse_id)=>{
	return post('warehouses/inventory/report', {fromDate, toDate, warehouse_id})
}

const paidSupplierOrder = ( order_id )=>{
	return post('orders/paid', { order_id })
}

const sendPromotion = ( message, title, allClients=0 , clients )=>{
	return post('clients/promotion/send', { message, title, allClients: allClients ? 1 : 0, clients});
}

const groupRangeUpdate = ( group_id ,  range_id )=>{
	return post('clients/groups/range/update', { group_id ,  range_id })
}

const supplierSalesReportU = ( fromDate, toDate, supplier_id )=>{
	return post('suppliers/supplier/sales/report', { fromDate, toDate, supplier_id })
}

const activeClientsReport = ()=>{
	return post('clients/actives/report')
}

const getAvailableProducts = (range_id)=>{
	return post('products/list/available', { range_id });
}

const exportProductPrices = async ()=>{
	return download(`ProductosPrecios_${moment().format('YYYY-MM-DD')}.csv`, 'products/export/prices');
}

const exportInventory = async ()=>{
	return download(`Inventario_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`, 'products/export/inventory');
}

const getMetadata = async ()=>{
	return post('products/metadata/list');
}

const updateTaxData = (rfc_id, legal_name, email, rfc, regimen, use_cfdi, location_id, street, exterior_number, interior_number,neighborhood, zipcode, state, city, country, status )=>{
	return post('clients/taxinfo/update', { rfc_id, legal_name, email, rfc, regimen, use_cfdi, location_id, street, exterior_number, interior_number,neighborhood, zipcode, state, city, country, status });
}

const getSectionProductInventory = ( product_id, section_id, available )=>{
	return post('products/inventory/section', {product_id, section_id, available})
}

const getAdjustReasons = ()=>{
	return post('products/inventory/adjust/reasons')
}

const addAdjust = async( inventory_id, adjust_reason, amount, description )=>{
	return post('products/inventory/adjust/add', { inventory_id, adjust_reason, amount, description })
}

const addAdjustReason = ( reason_name )=>{
	return post('products/inventory/adjust/reasons/add', { reason_name })
}

const getProductAdjusts = ( product_id )=>{
	return post('products/inventory/adjusts/get', { product_id })
}

const searchClientOrders = ( query, cancelled, date_from, date_to )=>{
	return post('clients/orders/search', { query, cancelled,  date_from, date_to })
}

const sendInvoiceEmail = ( id, business_id )=>{
	return post('clients/invoice/send', { id, business_id })
}
const sendComplementEmail = ( id, business_id )=>{
	return post('clients/complement/send', { id, business_id })
}

const deleteProductType = (type_id)=>{
	return post('products/type/delete', { type_id })
}

const activateProductType = (type_id)=>{
	return post('products/type/activate', { type_id })
}

const clientsOrdesSales = async (offset=0, count=50, date_from, date_to)=>{
	return post('clients/sales', { offset, count, date_from, date_to })
}

const searchOrdersSales = ( query, date_from, date_to )=>{
	return post('clients/sales/search', { query, date_from, date_to })
}

const productSalesReport = (fromDate, toDate)=>{
	return post('orders/products/sales/report', { fromDate, toDate})
}

const getSellerReport = async ()=>{
	return post('users/seller/report')
}

const getSellerSales = async (seller_id)=>{
	return post('users/seller/sales', { seller_id })
}

const downloadRemision = ( order_id )=>{
	return post('orders/order/remision', { order_id })
}

const sellerDebt = ( )=>{
	return post('users/seller/debt')
}

const clientsDebts = ()=>{
	return post('users/clients/debts')
}

const searchClientsDebts = (query, offset=0, count=75 )=>{
	return post('users/clients/debts/search', { query, offset, count})
}

const clientDebts = ( business_id )=>{
	return post('users/client/debts', { business_id })
}

const supplierOrderPayment = ( order_id, paymenth_method, amount_paid, external_id, date_paid ) => {
	return post ('orders/order/payment', { order_id, paymenth_method, amount_paid, external_id, date_paid })
}

const updatePresentation = ( presentation_id, status ) =>{
	return post('products/product/presentation/update', { presentation_id, status })
}

const getRangeClientsProspects = (range_id)=>{
	return post('ranges/range/clients/prospects', { range_id })
}

const getRangeGroupsProspects = (range_id)=>{
	return post('ranges/range/groups/prospects', { range_id })
}

const debtClientsReport = (date_from, date_to )=>{
	return post('clients/debts/report', { date_from, date_to  })
}

const cancelPayment = (payment_id)=>{
	return post('clients/payment/cancel', { payment_id })
}


const getClientsWithoutSales = async ()=>{
	return post('clients/withoutSales')
}

const createPromotion = async (promotionData)=>{
	return post('clients/promotions/create', promotionData);
}

const getPromotions = async (inactive=0)=>{	
	return post('clients/promotions/list', {inactive} );
}
const getPromotion = async (promotion_id)=>{
	return post('clients/promotion/get', {promotion_id});
}

const updatePromotion = async (promotion_id, active)=>{
	return post('clients/promotion/update', {promotion_id, active});
}

const checkPromotion = async (category, products, suppliers, items, all_customers, customers, segments, price_ranges )=>{
	return post('clients/promotion/check', { category, products, suppliers, items, all_customers, customers, segments, price_ranges });
}

const getTridimensionalReport = async (fromDate, toDate, type)=>{
	return post('orders/tridimensional/report', { fromDate, toDate, type });
}

const getPaymentsSummary = async (fromDate, toDate)=>{
	return post('orders/payments/summary', { fromDate, toDate });
}

const getPaymentDetails = async (method_id, invoice, fromDate, toDate)=>{
	return post('orders/payment/details', { method_id, invoice, fromDate, toDate });
}

const getActivePromotions = async (business_id)=>{
	return post('clients/promotions/active', { business_id });
}

const getPromotionProgress = async (business_id, product_id, promotion_id, all_products) => {
	return post('clients/promotion/progress', { business_id, product_id, promotion_id, all_products });
}

const getExpenseCategories = async ()=>{
	return post('orders/expenses/categories');
}

const createExpenseCategory = async (category_name)=>{
	return post('orders/expenses/categories/create', {category_name});
}

const createExpense = async (category, amount, payment_method, date, description)=>{
	return post('orders/expenses/create', {category, amount, payment_method, date, description});
}

const editExpenseCategory = async (category_id, category_name)=>{
	return post('orders/expenses/categories/edit', {category_id, category_name});
}

const deleteExpenseCategory = async (category_id)=>{
	return post('orders/expenses/categories/delete', {category_id});
}

const activateExpenseCategory = async (category_id)=>{
	return post('orders/expenses/categories/activate', {category_id});
}

const getBalanceReport = async (fromDate, toDate)=>{
	return post('orders/balance/report', { fromDate, toDate });
}

const getExpenseDetails = async (expense_id)=>{
	return post('orders/expense/get', { expense_id });
}

const receiveItem = async (order_item_id, quantity, section_id, batch, expiration_date)=>{
	return post('orders/inventory/add', { order_item_id, quantity, section_id, batch, expiration_date });
}

const receiveAllItems = async (items, section_id)=>{
	return post('orders/inventory/add/all', { items, section_id });
}

const setOrderDelivered = async (order_id)=>{
	return post('orders/set/delivered', { order_id });
}

const getAllInventoryAvailable = async ()=>{
	return post('products/inventory/available');
}

const modifyProductFromOrder = async (order_id, product_id, amount)=>{
	return post('orders/product/modify', { order_id, product_id, amount });
}

const removeProductFromOrder = async (order_id, product_id)=>{
	return post('orders/product/remove', { order_id, product_id });
}

const addProductsToOrder = async(business_id, order_id, payment_method, cart)=>{
	return post('clients/order/update', { business_id, order_id, payment_method, cart })
}
const convertQuoteToOrder = async(order_id, email_extra)=>{
	return post('clients/order/quote/convert', { order_id, email_extra })
}
const getSuggestedOrders = async (business_id)=>{
	return post('clients/suggested_orders/get', { business_id })
}

const getProductStockAvailable = async( product_id, business_id)=>{
	return post('products/quantity/get', { product_id, business_id });
};

const getProductStockRevision = async( product_id, quantity )=>{
	return post('products/stock', { product_id, quantity })
};

const getPendingPayments = async ()=>{
	return post('clients/payments/pending');
}

const processPayments = async (payments)=>{
	return post('clients/payments/process', { payments });
}

const getBankIncomes = async ()=>{
	return post('clients/bank/incomes/get');
}

const settleRemainingIncome = async (income)=>{
	return post('clients/bank/incomes/settle', { income });
} 
export default {
	// getRangeClients,
	// getRangeGroups,
	activateProductType,
	activateClient,
	addAdjustReason,
	addClientTaxData,
	activateGroup,
	addAdjust,
	activeClientsReport,
	activateProduct,
	debtClientsReport,
	activateRange,
	activateRoute,
	cancelPayment,
	downloadRemision,
	activateSupplier,
	activateUser,
	getAvailableProducts,
	getRangeClientsProspects,
	getRangeGroupsProspects,
	activateWarehouse,
	getClientIncentives,
	activeRoutes,
	addClientRoute,
	addGroupMember,
	addProductInventory,
	addProductToInventory,
	addSupplierContact,
	addUserPermissions,
	approveClient,
	cancelCredit,
	cancelInvoice,
	cancelClientOrder,
	cancelOrder,
	clientTaxDataList,
	clientsOrdesSales,
	changeBusinessType,
	changeClientRange,
	changeClientRoute,
	checkCartExistence,
	checkPromotion,
	clientSalesReport,
	clientDebts,
	clientsDebts,
	createClientContact,
	createClientCredit,
	createClientEvent,
	createClientOrder,
	createClientType,
	createGroup,
	createInvoice,
	createOrder,
	createPresentation,
	createProduct,
	createProductType,
	createRange,
	createClientIncentive,
	createPromotion,
	createRoute,
	createRun,
	createSupplier,
	createSupplierOrder,
	createUnit,
	createUser,
	createUserIncentive,
	createWarehouseSection,
	deleteClient,
	downloadInvoiceComplement,
	deleteClientRoute,
	deleteGroup,
	deleteClientIncentive,
	deleteProduct,
	deleteProductImage,
	deleteRange,
	deleteProductType,
	deleteRoute,
	deleteSupplier,
	deleteUser,
	deleteUserIncentive,
	deleteWarehouse,
	downloadInvoice,
	editClientCredit,
	editClientType,
	editProductMetadata,
	editProductPrice,
	editProductType,
	editClientIncentive,
	editProductUnit,
	editSupplier,
	editSupplierContact,
	editUserIncentive,
	exportProductPrices,
	exportInventory,
	getActiveClients,
	getSellerClientsInfo,
	getActiveGroups,
	getActiveRuns,
	getActiveWarehouses,
	getAdminTypes,
	getAllPermissions,
	getBusinessInfo,
	getBusinessOrderInfo,
	getClientContacts,
	getClientCredit,
	getClientDiscounts,
	getClientInfo,
	getClientOrderDetails,
	getClientRouteDays,
	getClientRouteWeekdays,
	getClientsOrders,
	getClientsWithSales,
	getProductAdjusts,
	getClientTaxdata,
	getClientTypes,
	getAdjustReasons,
	getContactInfo,
	getCreditLog,
	getDeletedGroups,
	getDeletedProductTypes,
	getDeletedRoutes,
	getDeletedSuppliers,
	getGroupInfo,
	getGroupMembers,
	getInactiveClients,
	getInactivesPrices,
	getInactiveUsers,
	getInactiveWarehouses,
	getInventorySupplierProducts,
	getMetadata,
	getNoRouteClients,
	getNotGroupedBusiness,
	getOrder,
	getSectionProductInventory,
	getOrders,
	getPaymentDetails,
	getPaymentMethods,
	getPaymentsSummary,
	getPendingClientCount,
	getPermissionsCategories,
	getPriceRanges,
	getProduct,
	getProductAvailability,
	getProductExistence,
	getProductInSections,
	getProductPresentations,
	getProductTypes,
	getPromotions,
	getPromotion,
	getSupplierIncentives,
	getRange,
	getRoute,
	getRouteClients,
	getRoutePendingOrders,
	getRoutes,
	getRouteWeekDays,
	getRunDetails,
	getSatCodes,
	getSectionProducts,
	getSellerActiveClients,
	getSellerInactiveClients,
	getSellerReport,
	getSellerSales,
	getSupplier,
	getSupplierOrders,
	getSupplierProducts,
	getSuppliers,
	getTridimensionalReport,
	getUnits,
	getUser,
	getUserIncentives,
	getUserPermissions,
	getUsers,
	getUsersWithAccess,
	getWarehouse,
	getWarehouses,
	getWarehouseSections,
	groupSalesReport,
	groupRangeUpdate,
	listMissingProducts,
	listProducts,
	Login,
	paidSupplierOrder,
	PendingClients,
	registerClient,
	registerWarehouse,
	removeGroupMember,
	salesReport,
	sendEmail,
	productSalesReport,
	sendInvoiceEmail,
	setClientSeller,
	setOrderPaid,
	searchOrdersSales,
	setPrimaryImageProduct,
	stockRevision,
	supplierContacts,
	updateClient,
	updateClientContact,
	updateClientLocation,
	updateGroup,
	updatePriceRange,
	updateProduct,
	updatePromotion,
	updateRoute,
	updateSupplier,
	updateSupplierContact,
	updateUser,
	searchClientOrders,
	updateWarehouse,
	uploadProductImage,
	sendPromotion,
	supplierSalesReport,
	warehouseInventoryReport,
	sellerDebt,
	supplierSalesReportU,
	updateTaxData,
	searchClientsDebts,
	supplierOrderPayment,
	updatePresentation,
	getClientsWithoutSales,
	getSellers,
	listPriceProducts,
	getActiveCustomersTrend,
	sendComplementEmail,
	getActivePromotions,
	getPromotionProgress,
	getExpenseCategories,
	createExpense,
	createExpenseCategory,
	editExpenseCategory,
	deleteExpenseCategory,
	activateExpenseCategory,
	getBalanceReport,
	getExpenseDetails,
	receiveItem,
	receiveAllItems,
	setOrderDelivered,
	getAllInventoryAvailable,
	modifyProductFromOrder,
	removeProductFromOrder,
	addProductsToOrder,
	updateProductDescription,
	convertQuoteToOrder,
	logout,
	getSuggestedOrders,
	getProductStockAvailable,
	getProductStockRevision,
	getPendingPayments,
	processPayments,
	getBankIncomes,
	settleRemainingIncome
}