const CryptoJS = require("crypto-js");

const secretKey = process.env.VUE_APP_ENCRYPT_KEY;

const addCommas = (num, fixed = true, hideZero = false) => {
	if (!num && num !== 0) return "ERR";
	var c = parseFloat(num).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	var dec = c.substring(-2, 2);
	return fixed
		? hideZero && dec == "00"
		? c.slice(0, -3)
		: c
		: c.slice(0, -3);
};

function orderPayment(order) {
	if (order.cancelled) {
		return "Cancelada";
	} else if (!order.accepted) {
		return "Pendiente";
	} else if (!order.paid) {
		return "No pagada";
	} else if (order.paid && !order.factura) {
		return "Pagada (factura pendiente)";
	} else if (order.paid && order.factura && !order.delivered) {
		return "Pagada (entrega pendiente)";
	} else if (order.delivered) {
		return "Pagada y entregada";
	}
}

function invoiceStatus(order) {
	if (order.cancelled) {
		return "Cancelada";
	} else if (!order.accepted) {
		return "Pendiente";
	} else if (!order.paid) {
		return "No facturada";
	} else if (order.paid && !order.factura) {
		return "Factura pendiente";
	} else if (order.paid && order.factura && !order.delivered) {
		return "Facturada (entrega pendiente)";
	} else if (order.delivered) {
		return "Facturada y entregada";
	}
}

function supplierOrderStatus(order) {
	if (order.cancelled === 1) {
		return "Cancelada";
	} else if (order.delivered === 1) {
		return "Entregada";
	} else if (order.factura === 1) {
		return "Enviada";
	} else if (order.shipped === 1) {
		return "Enviada";
	} else if (order.paid === 1) {
		return "Pagada";
	} else if (order.accepted === 1) {
		return "Aceptada";
	} else {
		return "Generada";
	}
}

function formatPlacesComponents(result) {
	var place = {
		name: result.name,
		place_id: result.place_id,
		street: null,
		exterior_number: null,
		interior_number: null,
		neighborhood: null,
		city: null,
		zipcode: null,
		state: null,
		country: null,
		lattitude: null,
		longitude: null
	}
	var place_identifiers = {
		street: {
			type: ['street_address', 'route'],
		},
		exterior_number: {
			type: 'street_number'
		},
		interior_number: {
			type: 'room'
		},
		neighborhood: {
			type: ['neighborhood', 'sublocality']
		},
		city: {
			type: 'locality'
		},
		zipcode: {
			type: 'postal_code'
		},
		state: {
			long_name: true,
			type: 'administrative_area_level_1'
		},
		country: {
			type: 'country'
		}
	}

	var coords = result.geometry.location.toJSON();
	place.longitude = coords.lng;
	place.lattitude = coords.lat;

	for (var i of result.address_components) {
		for (var k of Object.keys(place_identifiers)) {
			var types = place_identifiers[k].type;
			if (!Array.isArray(types)) types = [types];
			for (var t of types) {
				if (i.types.indexOf(t) != -1) {
					if (k == 'address_2' && place['address_1'] == i.long_name) continue;
					place[k] = place_identifiers[k].long_name === false ? i.short_name : i.long_name
					break;
				}
			}
		}
	}
	return place;
}

const timeConverter = (epochTime) => {
	const date = new Date(epochTime * 1000); 
	const year = date.getFullYear();
	const month = ("0" + (date.getMonth() + 1)).slice(-2); 
	const day = ("0" + date.getDate()).slice(-2); 
	const formattedDate = year + "-" + month + "-" + day;
	return formattedDate;
}

const orderStatus = (order) => {
	if (order.cancelled) return 'Cancelada'
	else if (order.delivered) return "Entregada"
	else if (order.shipped) return "En Reparto"
	else if (order.paid) return 'Pagada'
	else return 'Generada'
}

const runOrderStatus = (order) => {
	if (order.rejected && !order.delivered) return 'Rechazada'
	else if (order.delivered) return "Entregada"
	else if (order.prepared) return "Preparada"
	else return 'Sin Preparar'
}

const getDays = (days) => {
	var route_days = [];
	if (days.includes(0)) route_days.push('Dom')
	if (days.includes(1)) route_days.push('Lun')
	if (days.includes(2)) route_days.push('Mar')
	if (days.includes(3)) route_days.push('Mie')
	if (days.includes(4)) route_days.push('Jue')
	if (days.includes(5)) route_days.push('Vie')
	if (days.includes(6)) route_days.push('Sab')

	return route_days.join(", ");
};

function roundNumber(num) {
	return +(Math.round(num + "e+2") + "e-2");
}

const routeDays = (days) => {
	var route_days = [];
	var current_date = new Date();
	current_date.setHours("12")
	current_date.setMinutes("00")
	current_date.setSeconds("00")
	current_date.setMilliseconds("00")
	var weekdays = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
	var current_day = current_date.getDay();

	for (let index = 0; index <= 6; index++) {
		if (days.includes(index) && current_day < index) {
			route_days.push({
				day: weekdays[index],
				time: current_date.setDate(current_date.getDate()) + (index - current_day) * (86400000),
				date: new Date(current_date.setDate(current_date.getDate()) + (index - current_day) * (86400000)).toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric" })
			})
		}
		if (days.includes(index) && current_day >= index) {
			var next_week = new Date(current_date.getTime() + 7 * 24 * 60 * 60 * 1000)
			route_days.push({
				day: weekdays[index],
				time: next_week.setDate(next_week.getDate()) + (index + (7 - current_day)) * (86400000),
				date: new Date(next_week.setDate(next_week.getDate()) + (index - current_day) * (86400000)).toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric" })
			});
		}
	}

	return route_days;
};

const getExpectedDelivery = (weekdays)=>{
	if(!weekdays || weekdays.length==0) return null;
	var expected = new Date();
	var now_weekday = expected.getDay();
	weekdays = weekdays.filter(a=>a>=0 && a<=6).sort((a,b)=>a-b)
	var next_weekday = weekdays.find(a=>a>now_weekday);
	if(!next_weekday) next_weekday = (7-now_weekday)+weekdays[0];
	else next_weekday = next_weekday-now_weekday;
	expected.setDate(expected.getDate()+next_weekday);
	var month = expected.getMonth();
	if (month < 10) {
		month = "0" + month;
	}
	return expected.getDate() +'/'+ month +'/'+ expected.getFullYear(); 
};

const runStatus = (run)=>{
	if(run.finished) return "Finalizada"
	else if(run.shipped) return "En Ruta"
	else return "En Preparación"
};

const daysDifference = (time)=>{
	var now_date = new Date();
	now_date.setHours(0, 0, 0, 0);
	var then_date = new Date(time*1000);
	then_date.setHours(0, 0, 0, 0);
	var now = Math.floor(now_date.getTime()/1000);
	var then = Math.floor(then_date.getTime()/1000);
	return Math.floor((then-now)/(3600*24));
}

function randomRange(min, max){
	return Math.floor(Math.random() * (max-min+1)+min);
}

function randomCode(length, tolerance=0, lowercase=false){
	var codeChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
	if(lowercase) codeChars += 'abcdefghijklmnopqrstuvwxyz';
	if(tolerance) length += randomRange(-tolerance, tolerance);

	var code = "";
	for (var i = 0; i < length; i++) code += codeChars[Math.floor(Math.random() * codeChars.length)];
	return code;
}

function getDeliveryDays(days, weeks=3) {
	const textDays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
	var dates = [];
	for(var w=0; w<weeks; w++){
		for(var i of days){
			var today = new Date();
			var today_weekday = today.getDay();
			var d = new Date();
			d.setDate(d.getDate() + (7*w));
			d.setHours(12, 0, 0, 0);
			if(i==today_weekday){
				d.setDate(d.getDate() + 7 + (7 + i - d.getDay()) % 7);
			}else{
				d.setDate(d.getDate() + (7 + i - d.getDay()) % 7);
			}
			dates.push({ weekday: i, day: textDays[i], unix: Math.floor(d.getTime()/1000) });
		}
	}

	return dates.sort((a,b)=>a.unix-b.unix);
};

function epochToDate(epoch) {
	const date = new Date(epoch * 1000);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	return `${day}/${month}/${year}`;
}

function getPaymentMethods(business) {
	const paymentMethods = [
	  { key: 2, text: "CoDi®", value: 2 },
	  { key: 3, text: "Pago a repartidor", value: 3 },
	  { key: 4, text: "Saldo", value: 5 }
	];
 
	if ((business.creditStatus.credit_limit > business.creditStatus.used_credit) && (business.creditStatus.unpaid_orders < business.creditStatus.credit_orders) ) {
	  paymentMethods.splice(2, 0, { key: 4, text: "Credito", value: 4 });
	}
	return paymentMethods;
 }

function getEpochSeconds(dateString) {
	const [day, month, year] = dateString.split("/");
	const dateObj = new Date(year, month - 1, day);
	return Math.round(dateObj.getTime() / 1000);
 }

 function getDaysLeft(epochDate) {
	const currentDate = new Date();
	const targetDate = new Date(epochDate * 1000);
 
	currentDate.setHours(0, 0, 0, 0);
	targetDate.setHours(0, 0, 0, 0);
 
	const timeDiff = targetDate.getTime() - currentDate.getTime();
 
	const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
 
	return daysLeft;
 }

 function getDaysFromEpochToDate(epochDate) {
	const today = new Date();
	const epochMilliseconds = epochDate * 1000;
	const timeDiff = today.getTime() - epochMilliseconds;
	const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
	return daysDiff;
 }

 function toEpochDate(epochTimeInSeconds) {
	const millisecondsPerDay = 24 * 60 * 60 * 1000;
	const today = new Date();
	const targetDate = new Date(epochTimeInSeconds * 1000);
	const timeDiff = targetDate.getTime() - today.getTime();
	
	if (timeDiff < 0) {
	  return 'Sin fecha';
	}
 
	const daysDiff = Math.ceil(timeDiff / millisecondsPerDay);
	return daysDiff + " Dias";
 }

 function calculateTotal(products) {
	let total = 0;
	for (let i = 0; i < products.length; i++) {
	  const p = products[i];
	  const result = p.has_iva ? (p.quantity * p.supplier_price) * (1 + p.ieps_rate) * 1.16 : (p.quantity * p.supplier_price) * (1 + p.ieps_rate);
	  total += result;
	}
	return total.toFixed(2);
 }

 function calculateDaysRemaining(paymentDays, deliveryDateEpoch) {
	if (deliveryDateEpoch === null) {
	  return null;
	}
 
	const daysDifference = ((Date.now() / 1000) - deliveryDateEpoch ) / (60 * 60 * 24);
	const daysRemaining = Math.floor(paymentDays - daysDifference);
	return daysRemaining;
 }

 const checkCreditStatus = (credit) =>{
	if(credit.available_credit>0 && credit.unpaid_orders<credit.credit_orders && parseInt(credit.expired_orders) == 0) return true;
	return false;
}

function getUniqueObjects(arr) {
    const seen = new Set();
    return arr.filter(obj => {
      const key = obj.value + '|' + obj.label;       
      if (!seen.has(key)) {
        seen.add(key);
        return true;
      }
      return false;
    });
  }

// Encrypt function
export function encryptData(data) {	
    // Convert numbers to strings for encryption
    const dataStr = typeof data === 'number' ? data.toString() : data;
    return CryptoJS.AES.encrypt(dataStr, secretKey).toString();
}

// Decrypt function
export function decryptData(encryptedData) {
	if(!encryptedData) return null;
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    // Try converting the decrypted string back to a number
    const num = Number(decryptedData);
    return isNaN(num) ? decryptedData : num;
}

export function convertSpanishDate(spanishDate) {
	// Map Spanish month abbreviations to month numbers
	  const months = {
		Ene: 0,  // January
		Feb: 1,  // February
		Mar: 2,  // March
		Abr: 3,  // April
		May: 4,  // May
		Jun: 5,  // June
		Jul: 6,  // July
		Ago: 7,  // August
		Sep: 8,  // September
		Oct: 9,  // October
		Nov: 10, // November
		Dic: 11  // December
	  };
  
	  // Extract day, month abbreviation, and year using regex
	  const match = spanishDate.match(/(\d{2})\/([A-Za-z]+)\.\/(\d{4})/);
	  if (!match) {
		throw new Error(`Invalid date format: ${spanishDate}`);
	  }
  
	  const day = parseInt(match[1], 10);
	  const month = months[match[2]];
	  const year = parseInt(match[3], 10);
  
	  if (month === undefined) {
		throw new Error(`Invalid month abbreviation: ${match[2]}`);
	  }
  
	  // Create and return the Date object
	  return new Date(year, month, day);
	};

export function saveFilters(filters, key) {
	const filtermodel = filters.getFilterModel();
	sessionStorage.setItem(key, JSON.stringify(filtermodel));
}

export function restoreFilters(gridApi, key) {
	const filterModel = gridApi && sessionStorage.getItem(key)!='undefined' ? JSON.parse(sessionStorage.getItem(key)) : null;
	if (filterModel) {
		gridApi.setFilterModel(filterModel);
	}
}

export default {
	calculateDaysRemaining,
	addCommas,
	toEpochDate,
	daysDifference,
	epochToDate,
	formatPlacesComponents,
	getDays,
	getExpectedDelivery,
	getDeliveryDays,
	getDaysFromEpochToDate,
	invoiceStatus,
	orderPayment,
	orderStatus,
	randomCode,
	getDaysLeft,
	randomRange,
	routeDays,
	runOrderStatus,
	runStatus,
	supplierOrderStatus,
	timeConverter,
	getPaymentMethods,
	roundNumber,
	getEpochSeconds,
	calculateTotal,
	checkCreditStatus,
	getUniqueObjects,
	encryptData,
	decryptData,
	convertSpanishDate,
	saveFilters,
	restoreFilters
}